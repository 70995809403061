<template>
    <div>
        <div class="top_div">
            <div class="search_div_father" style="position: relative">
                <!-- <div
                    style="
                        display: flex;
                        position: absolute;
                        z-index: 1;
                        top: 65px;
                        left: calc(50% - 480px);
                    "
                >
                    <TabBarImage :value="showTab" @update="showTabClick" />
                </div> -->
                <common-header
                    :title="`${showTabTitle}港箱货跟踪历史记录`"
                ></common-header>
            </div>
        </div>

        <div class="data_div">
            <div class="data_search_div">
                <div class="data_search_left_div">
                    <el-button
                        type="success"
                        plain
                        @click="recallBoxList()"
                        size="small"
                    >
                        批量还原
                    </el-button>
                    <el-popover
                        placement="top"
                        trigger="manual"
                        v-model="popoverVisible"
                        :content="popoverContent"
                    >
                        <div
                            slot="reference"
                            class="search_input_div"
                            style="margin-left: 30px"
                        >
                            <el-autocomplete
                                popper-class="vessel-voyage-popper"
                                clearable
                                @clear="blurForBug()"
                                class="inline-input"
                                v-model="searchVesselenameVoyage"
                                @input="checkSearchVesselenameVoyage()"
                                :fetch-suggestions="querySearch"
                                placeholder="输入船名/航次"
                                :trigger-on-focus="false"
                                @select="handleSelect"
                                @keyup.enter.native="selectInfo()"
                            ></el-autocomplete>
                            <!-- 上海Start -->
                            <!-- <el-select
                                v-else
                                clearable
                                filterable
                                placeholder="请选择船司"
                                style="width: 300px"
                                v-model="shopCompanyValue"
                            >
                                <el-option
                                    v-for="item in shopCompanyList"
                                    :key="item.code"
                                    :label="item.mix"
                                    :value="item.code"
                                >
                                    <span style="float: left">
                                        {{ item.name }}
                                    </span>
                                    <span
                                        style="
                                            float: right;
                                            color: #8492a6;
                                            font-size: 13px;
                                        "
                                    >
                                        {{ item.code }}
                                    </span>
                                </el-option>
                            </el-select> -->
                            <!-- 上海End -->
                            <el-autocomplete
                                clearable
                                @clear="blurForBug()"
                                class="inline-input input-with-select query-input_right"
                                v-model="searchBlno"
                                @input="checkSearchBlno()"
                                :fetch-suggestions="querySearchBlno"
                                placeholder="输入提单号搜索"
                                :trigger-on-focus="false"
                                @keyup.enter.native="selectInfo()"
                            >
                                <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                    @click="selectInfo()"
                                ></el-button>
                            </el-autocomplete>
                            <el-button
                                class="refresh_btn"
                                size="small"
                                type="success"
                                plain
                                @click="refreshBoxList()"
                            >
                                重新载入
                            </el-button>
                        </div>
                    </el-popover>
                </div>

                <div class="flex_div">
                    <column-set
                        :table-list="historyList"
                        page-key="history"
                        useSystemKey
                        :reset-data="{
                            NB: RESETTABLE.resetTableNB,
                            SH: RESETTABLE.resetTableNB,
                            QD: RESETTABLE.resetTableNB,
                            TJ: RESETTABLE.resetTableNB,
                            // SH: RESETTABLE.sResetTable,
                            // QD: RESETTABLE.qdResetTable,
                        }"
                        :save-mutation="{
                            NB: 'table/editHistoryList',
                            SH: 'table/editHistoryList',
                            QD: 'table/editHistoryList',
                            TJ: 'table/editHistoryList',
                            // SH: 'table/editHistoryListSH',
                            // QD: 'table/editHistoryListQD',
                        }"
                        :reset-mutation="{
                            NB: 'table/resetHistoryList',
                            SH: 'table/resetHistoryList',
                            QD: 'table/resetHistoryList',
                            TJ: 'table/resetHistoryList',
                            // SH: 'table/resetHistoryListSH',
                            // QD: 'table/resetHistoryListQD',
                        }"
                    ></column-set>
                </div>
            </div>

            <div class="table_div">
                <el-table
                    border
                    stripe
                    max-height="530px"
                    v-loading="loading"
                    ref="multipleTable"
                    :key="tableKey"
                    :cell-class-name="cellClass"
                    :data="boxList"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    @header-dragend="handleHeaderChange"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        type="selection"
                        :selectable="selectable"
                        width="50"
                    ></el-table-column>
                    <el-table-column
                        type="index"
                        width="50"
                        fixed="left"
                        center
                    >
                        <template slot-scope="scope">
                            <p>{{ scope.row.index }}</p>
                        </template>
                    </el-table-column>

                    <template v-for="item in historyList">
                        <el-table-column
                            v-if="item.show"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.prop === 'area' && item.show">
                                    <PlaceShow name="NB" />
                                </span>
                                <p v-else-if="item.prop === 'billNo'">
                                    <span
                                        v-if="
                                            scope.row.sonList &&
                                            scope.row.sonList.length > 0 &&
                                            showList.findIndex(
                                                (item) =>
                                                    item == scope.row.blno,
                                            ) == -1
                                        "
                                        class="addshow_class"
                                        style="color: blue; cursor: pointer"
                                        @click="showAllList(scope.row.blno)"
                                    >
                                        +
                                    </span>
                                    <span
                                        v-if="
                                            scope.row.sonList &&
                                            scope.row.sonList.length > 0 &&
                                            showList.findIndex(
                                                (item) =>
                                                    item == scope.row.blno,
                                            ) != -1
                                        "
                                        class="reduceshow_class"
                                        style="color: blue; cursor: pointer"
                                        @click="showOneList(scope.row.blno)"
                                    >
                                        -
                                    </span>
                                    <span>{{ scope.row.blno }}</span>
                                    <span
                                        v-if="
                                            scope.row.sonCount &&
                                            scope.row.sonCount !== 1
                                        "
                                        class="together_num_class"
                                        style="color: blue"
                                    >
                                        {{ scope.row.sonCount }}
                                    </span>
                                </p>
                                <template v-if="item.prop === 'shipInfo'">
                                    <p
                                        v-if="
                                            !scope.row.vesselsName &&
                                            !scope.row.voyage &&
                                            !scope.row.boxArrivalCount &&
                                            scope.row.sonCount
                                        "
                                        class="add_remark_p"
                                    >
                                        添加船名/航次
                                    </p>
                                    <div v-else>
                                        <span>{{ scope.row.vesselsName }}</span>
                                        <p>{{ scope.row.voyage }}</p>
                                    </div>
                                </template>
                                <div
                                    v-if="
                                        scope.row.vesselsName &&
                                        scope.row.voyage &&
                                        item.prop === 'shipPosition'
                                    "
                                    style="
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                    "
                                >
                                    <el-image
                                        class="class_ship"
                                        :src="ship"
                                    ></el-image>
                                </div>
                                <span v-if="item.prop === 'wharf'">
                                    {{ getMatou(scope.row[item.prop]) }}
                                </span>
                                <span v-else-if="item.prop === 'remark'">
                                    <p
                                        v-if="!scope.row[item.prop]"
                                        class="add_remark_p"
                                    >
                                        添加备注
                                    </p>
                                    <span v-else>
                                        {{ scope.row[item.prop] }}
                                    </span>
                                </span>
                                <!-- 码放 -->
                                <el-tooltip
                                    v-else-if="item.prop === 'nppStatus'"
                                    class="item"
                                    effect="dark"
                                    :disabled="!scope.row.wharfRemark"
                                    :content="scope.row.wharfRemark"
                                    placement="top"
                                >
                                    <span
                                        :class="[
                                            {
                                                p_green:
                                                    scope.row[item.prop] ===
                                                    'Y',
                                            },
                                            {
                                                p_red:
                                                    scope.row[item.prop] ===
                                                    'N',
                                            },
                                        ]"
                                    >
                                        {{
                                            passFlag2String(
                                                scope.row[item.prop],
                                            )
                                        }}
                                    </span>
                                </el-tooltip>
                                <!-- 毛重误差 -->
                                <span v-else-if="item.prop === 'errorValue'">
                                    <el-image
                                        v-if="scope.row[item.prop] == 0"
                                        :src="icon_green"
                                        class="passIcon"
                                    ></el-image>
                                    <p v-else>
                                        {{
                                            scope.row[item.prop]
                                                ? `${scope.row[item.prop]}%`
                                                : ""
                                        }}
                                    </p>
                                </span>
                                <!-- 各类标志 -->
                                <span v-else-if="item.prop.includes('Flag')">
                                    <el-image
                                        v-if="scope.row[item.prop] === 'Y'"
                                        :src="icon_green"
                                        class="passIcon"
                                    ></el-image>
                                    <el-image
                                        v-if="scope.row[item.prop] === 'N'"
                                        :src="icon_red"
                                        class="failIcon"
                                    ></el-image>
                                </span>
                                <!-- 各类时间 -->
                                <span v-else-if="item.prop.includes('Time')">
                                    {{ formatTime(scope.row[item.prop]) }}
                                </span>

                                <span v-else>
                                    {{ scope.row[item.prop] }}
                                </span>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column
                        prop="deleteReason"
                        label="删除理由"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        fixed="right"
                        prop="address"
                        label="操作"
                        :formatter="formatter"
                        width="180"
                    >
                        <template slot-scope="scope">
                            <div class="table_control_div">
                                <p
                                    v-if="scope.row.psType == '1'"
                                    class="recall"
                                    @click="recallBoxItem(scope.row)"
                                >
                                    还原
                                </p>
                                <p v-else style="color: rgb(0, 200, 13)">-</p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination_div">
                <el-pagination
                    background
                    :hide-on-single-page="pageTotal < 10"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="sizes,prev, pager, next"
                    :total="pageTotal"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    :current-page.sync="pagePosition"
                ></el-pagination>
            </div>
        </div>
        <RocketHeaderDilaog
            width="400px"
            @close="handleInfoDialogStatus()"
            :visible.sync="infoDialogVisible"
            :imgTopTrans="120"
        >
            <div class="header-title">历史记录</div>
            <div v-html="dialogInfo" style="padding: 120px 20px 20px"></div>
            <DialogFooter
                @confirm="handleInfoDialogStatus(`localStorage`)"
                confirmText="不再提示"
                @cancel="handleInfoDialogStatus(`sessionStorage`)"
                cancelText="我知道了"
                style="padding-bottom: 20px;"
            />
        </RocketHeaderDilaog>
        
        <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog>
        <recall-box-dialog
            v-if="recallDialogVisible"
            ref="RecallBoxDialog"
            @refreshDataList="getBoxListByPage()"
        ></recall-box-dialog>
    </div>
</template>

<script>
import ColumnSet from "@/components/common/ColumnSet";
import CommonHeader from "@/components/common/CommonHeader";
import TableHeaderTooltip from "@/components/common/TableHeaderTooltip";
import LoginDialog from "@/components/register/WechatLoginDialog";
import RecallBoxDialog from "./components/RecallBoxDialog";
import RocketHeaderDilaog from "@/components/layout/RocketHeaderDilaog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import {
    setRightHistoryForrmatSpace2,
    setRightForrmat,
    setRightForrmatSpace,
    getDate,
    getDate2,
    getTime,
    getTime2,
    checkCusmovIsCheck,
    checkDeletedReason,
    getMatou,
} from "@/utils/validate";
import { passFlag2String } from "@/utils";
import { deleteCarPosition } from "@/api/tracking";
import TabBarImage from "@/components/common/TabBarImage";
import PlaceShow from "@/components/common/PlaceShow";
import { historyTableList } from "./tableList";
import { logisticsRequest } from "../Logistics/logistics";
export default {
    data() {
        return {
            showTab: "NB",
            icon_green: require("@/assets/img/green.png"),
            icon_red: require("@/assets/img/red.png"),
            ship: require("@/assets/img/chuan1.png"),
            loginDialogVisible: false,
            recallDialogVisible: false,
            popoverVisible: false,
            popoverContent: "",
            loading: false,
            test: "测试信息",
            loginInterval: "",
            vesselename: "",
            voyage: "",
            blno: "",
            searchBlno: "", //搜索框提单号
            searchVesselename: "", //搜索框船名
            searchVesselenameVoyage: "", //搜索框船名航次
            searchVoyage: "", //搜索框航次
            totalBoxList: [], //存放原始数组用于筛选
            totalVoyageList: [], //存放原始船名航次用于筛选
            totalBlnoList: [], //存放原始提单号用于筛选
            boxList: [], //展示用的列表数据
            multipleSelection: [], //勾选中的列表
            // 表格key
            tableKey: 1,
            // 列设置中 tree配置
            defaultProps: {
                children: "children",
                label: "label",
            },
            pageTotal: 0,
            pageSize: 10,
            pagePosition: 1,
            startRow: 0,
            shopCompanyList: [],
            shopCompanyValue: "",
            isNbSystem: true,
            nppOptions: [
                {
                    value: "",
                    label: "码头状态",
                },
                {
                    value: "Y",
                    label: "码头已放行",
                },
                {
                    value: "N",
                    label: "码头未放行",
                },
            ],
            customOptions: [
                {
                    value: "",
                    label: "海放状态",
                },
                {
                    value: "Y",
                    label: "海关已放行",
                },
                {
                    value: "N",
                    label: "海关未放行",
                },
            ],
            showList: [], //需展开的提单号,
            listData: [
                "1.主动删除",
                "2.离港后1个月",
                "3.添加订阅后1个月内无任何数据更新",
            ],
            dialogInfo:
                "<span>出现以下情形之一的会自动挪入到历史记录，不再进行后续的订阅更新。若是挪入有误，可以进行还原操作。</span><p>1.主动删除</p><p>2.离港后7天</p><p>3.添加订阅后7天内无任何数据更新</p><p>4.多方原因未完结7天</p>",
            infoDialogVisible: false,
        };
    },
    components: {
        LoginDialog,
        RecallBoxDialog,
        CommonHeader,
        TableHeaderTooltip,
        ColumnSet,
        TabBarImage,
        PlaceShow,
        RocketHeaderDilaog,
        DialogFooter,
    },
    created() {
        this.showTab = localStorage.getItem("showTab") || this.showTab;
    },
    mounted() {
        this.Init();
    },
    computed: {
        historyList: {
            get() {
                return historyTableList({
                    tab: this.showTab,
                    type: 1,
                });
            },
            set(val) {
                historyTableList({
                    tab: this.showTab,
                    type: 2,
                    data: val,
                });
            },
        },
        showTabTitle() {
            // const namePlaceEnum = {
            //     SH: "上海",
            //     NB: "宁波",
            //     QD: "青岛",
            // };
            // return namePlaceEnum[this.showTab];
            return "宁波";
        },
    },
    watch: {
        historyList: {
            handler(val) {
                this.tableKey += 1;
            },
            deep: true,
        },
    },
    methods: {
        showTabClick(val) {
            if (this.loading) {
                this.$message.error("请等待数据加载完成再重试，请勿频繁切换");
                return;
            }
            this.showTab = val;
            this.Init();
            localStorage.setItem("showTab", val);
            this.$store.commit("other/editSystemKey", val);
        },
        // 处理提示弹框
        handleInfoDialogStatus(type) {
            type === "localStorage"
                ? localStorage.setItem("historyInfoDialogStatus", false)
                : sessionStorage.setItem("historyInfoDialogStatus", false);
            this.infoDialogVisible = false;
        },
        Init() {
            window.sessionStorage.setItem("activeSearchPath", "/history"); //导航栏激活
            this.pageSize = this.$store.state.table.pageSize;
            // 判断是否是宁波港
            // this.isNbSystem = this.$store.state.other.systemKey === "NB";
            // 船司名称代码
            // this.shopCompanyList = this.SHOPCOMPANYLIST.shopCompanyList.map(
            //     (item) => {
            //         return { ...item, mix: item.code + "/" + item.name };
            //     },
            // );
            this.checkLogin();
            var newlen = 0;
            const curTableList = historyTableList({
                tab: this.showTab,
                type: 1,
            });
            for (var i = 0; i < curTableList.length; i++) {
                if (curTableList[i].label) {
                    newlen += curTableList[i].label.length;
                }
            }
            var oldlen = 0;
            // 判断是否是宁波港决定渲染列表数据
            const restTable = historyTableList({
                tab: this.showTab,
                type: 3,
            });
            var oldlen = 0;
            for (var i = 0; i < restTable.length; i++) {
                if (restTable[i].label) {
                    oldlen += restTable[i].label.length;
                }
            }
            if (newlen !== oldlen) {
                this.historyList = curTableList;
            } else {
                this.resetTable();
            }
        },

        resetTable() {
            this.historyList = historyTableList({
                tab: this.showTab,
                type: 3,
            });
            this.tableKey = 1;
        },

        //定时刷新列表
        refreshListInterval() {},
        //修改分页的pagesize
        handleSizeChange(val) {
            this.pageSize = val;
            this.pagePosition = 1;
            this.getBoxListByPage();
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getBoxListByPage();
        },
        //登录，显示二维码弹窗
        login() {
            this.loginDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LoginDialog.init();
            });
        },
        //定时执行
        checkLogin() {
            var that = this;
            this.loginInterval = setInterval(function () {
                that.checkToken();
            }, 10);
        },
        checkToken() {
            if (this.$store.state.user.token) {
                clearInterval(this.loginInterval);
                this.getBoxListByPage();
                this.getVoyageList();
                this.getBlnoList();
                this.infoDialogVisible =
                    !Boolean(localStorage.getItem("historyInfoDialogStatus")) &&
                    !Boolean(sessionStorage.getItem("historyInfoDialogStatus"));
            }
        },
        //选中的列表数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //自定义单元格宽度
        handleHeaderChange(newWidth, oldWidth, column, event) {
            this.historyList.forEach((item) => {
                if (item.label == column.label) {
                    item.width = newWidth;
                }
            });
            historyTableList({
                tab: this.showTab,
                type: 2,
                data: this.historyList,
            });
            // this.$store.commit("table/editHistoryList", this.historyList);
        },
        formatter(row, column) {
            return row.address;
        },
        //重置popver
        resetPopover() {
            this.popoverVisible = false;
            this.popoverContent = "";
        },
        //从列表中模糊匹配
        selectInfo() {
            if (!this.searchBlno && !this.searchVesselenameVoyage) {
                this.popoverVisible = true;
                this.popoverContent = "请输入关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
            if (
                this.searchBlno.length >= 4 ||
                this.searchVesselenameVoyage.length >= 4
            ) {
                if (this.searchVesselenameVoyage) {
                    if (this.searchVesselenameVoyage.split("/").length >= 2) {
                        var list = this.searchVesselenameVoyage.split("/");
                        var Vesselename = "";
                        list.forEach((item, index) => {
                            if (index == list.length - 1) {
                                return;
                            } else if (index == list.length - 2) {
                                Vesselename = Vesselename + item;
                            } else {
                                Vesselename = Vesselename + item + "/";
                            }
                        });
                        this.searchVesselename = Vesselename;
                        this.searchVoyage = list[list.length - 1];
                    } else {
                        return this.$message.error("请同时输入船名和航次");
                    }
                }
                this.pagePosition = 1;
                this.getBoxListByPage();
            } else {
                this.popoverVisible = true;
                this.popoverContent = "请输入长度至少为4位的关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
        },
        //重新载入符合状态的列表
        selectTypeList() {
            this.pagePosition = 1;
            this.getBoxListByPage();
        },
        //重置搜索条件，重新载入列表
        refreshBoxList() {
            this.searchBlno = "";
            this.searchVesselename = "";
            this.searchVesselenameVoyage = "";
            this.searchVoyage = "";
            this.pagePosition = 1;
            this.getBoxListByPage();
        },

        //检测搜索关键字格式
        checkSearchBlno() {
            if (this.searchBlno) {
                this.searchBlno = setRightForrmat(this.searchBlno);
            }
        },
        checkInputAlpha() {
            if (this.customizeNum) {
                this.customizeNum = this.checkRightFormat(this.customizeNum);
            }
        },
        checkSearchVesselename() {
            if (this.searchVesselename) {
                this.searchVesselename = setRightForrmatSpace(
                    this.searchVesselename,
                );
            }
        },
        showAllList(blno) {
            if (!this.showList.includes((item) => item.blno)) {
                this.showList.push(blno);
                this.getBoxListByPage();
            }
        },
        showOneList(blno) {
            let index = this.showList.findIndex((item) => item == blno);
            if (~index) {
                this.showList.splice(index, 1);
                this.getBoxListByPage();
            }
        },

        checkRightFormat(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z-\,]+"), //正则 英文+-；
                    str = "";
                for (var i = 0; i < val.length; i++) {
                    if (codeReg.test(val[i])) {
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },
        checkSearchVesselenameVoyage() {
            if (this.searchVesselenameVoyage) {
                this.searchVesselenameVoyage = setRightHistoryForrmatSpace2(
                    this.searchVesselenameVoyage,
                );
            }
        },
        checkSearchVoyage() {
            if (this.searchVoyage) {
                this.searchVoyage = setRightForrmat(this.searchVoyage);
            }
        },
        //船名航次搜索框带输入建议
        querySearch(queryString, cb) {
            var restaurants = this.totalVoyageList;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.vesselename
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1 ||
                    restaurant.voyage
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //船名航次搜索框选择了输入建议
        handleSelect(item) {
            this.searchVesselename = item.vesselename;
            this.searchVoyage = item.voyage;
        },
        //提单号搜索框带输入建议
        querySearchBlno(queryString, cb) {
            var restaurants = this.totalBlnoList;
            var results = queryString
                ? restaurants.filter(this.createFilterBlno(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilterBlno(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },

        // 时间处理，切割
        formatTime(time) {
            return time && time !== "-" ? time.slice(5, time.length - 3) : "-";
        },

        //获取全部的提单号用于搜索
        getBlnoList() {
            const reqFunc = logisticsRequest({
                tab: "NB" ?? this.showTab,
                type: 3,
            });

            reqFunc({
                // code: this.$store.state.other.systemKey,
                code: "NB" ?? this.showTab,
            }).then(({ data }) => {
                let list = data.data;
                if (list) {
                    this.totalBlnoList = list.map((item) => ({ value: item }));
                }
            });
        },
        //获取全部的船名航次用于搜索
        getVoyageList() {
            const reqFunc = logisticsRequest({
                tab: "NB" ?? this.showTab,
                type: 2,
            });

            reqFunc({
                // code: this.$store.state.other.systemKey,
                code: "NB" ?? this.showTab,
            }).then(({ data }) => {
                let list = data.data;
                if (list) {
                    this.totalVoyageList = list.map(
                        ({ vesselsName, voyage }) => ({
                            value: `${vesselsName}/${voyage}`,
                            vesselename: vesselsName,
                            voyage,
                        }),
                    );
                }
            });
        },
        //分页查箱单(历史记录)
        getBoxListByPage() {
            if (this.timeType && !this.searchtime) {
                this.$message.error("请输入搜索日期范围");
                return;
            }
            if (!this.timeType && this.searchtime) {
                this.$message.error("请输入搜索日期类别");
                return;
            }
            let param = {
                blno: this.searchBlno || undefined,
                pageNum: this.pagePosition,
                pageSize: this.pageSize,
                vesselsName: this.searchVesselename || undefined,
                voyage: this.searchVoyage || undefined,
                // code: this.$store.state.other.systemKey,

                code: "NB" || this.showTab,
            };

            this.loading = true;
            // const reqFunc =
            //     this.showTab === "NB"
            //         ? deleteCarPosition
            //         : this.showTab === "SH"
            //         ? deleteHistorySH
            //         : queryQDBlnoHistory;
            const reqFunc = deleteCarPosition;
            reqFunc(param)
                .then(({ data }) => {
                    let list = data.data.list;
                    if (list === null) {
                        this.totalBoxList = [];
                        this.boxList = [];
                    } else {
                        this.totalBoxList = list;
                        this.pageTotal = data.data.total;
                        this.startRow = data.data.startRow;
                        this.pagePosition = data.data.pageNum;
                    }

                    let checkShowList = this.totalBoxList.map((item) => {
                        if (this.showList.includes(item.blno)) {
                            return item.blno;
                        }
                    });
                    this.showList = checkShowList;
                    this.dealList(this.totalBoxList);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        dealList(list) {
            this.boxList = [];
            list.forEach((item) => {
                this.showList.includes(item.blno)
                    ? this.dealListTwo(item)
                    : this.dealListOne(item);
            });
        },
        //不展开
        dealListOne(item) {
            const returnItem = {
                ...item,
                index: this.tableIndex(this.boxList.length), //序号
                boxArrivalCount: item.inGateCount
                    ? item.inGateCount +
                      "箱/" +
                      (item.nppCtnCount ? item.nppCtnCount + "箱" : "NO")
                    : "", //箱子进港数量
                boxCusmov: checkCusmovIsCheck(item.checkType), //海关查验状态
                deleteReason: checkDeletedReason(item.deleteType), // 删除原因
            };
            this.boxList.push(returnItem);
        },
        //展开(能被展开的一定是有sonList)
        dealListTwo(list) {
            [list, ...list.sonList].forEach((item) => {
                this.dealListOne(item);
            });
        },
        // 生成还原订单数据
        generateRecallData(item) {
            const needRecall = item.sonList?.length
                ? [item, ...item.sonList]
                : [item];
            return needRecall.map(
                ({ blno, remark, vesselsName, voyage }, index) => ({
                    blno,
                    isCheck: needRecall.length === 1,
                    psType: index === 0 ? "1" : item.blno,
                    remark,
                    vesselsName,
                    voyage,
                }),
            );
        },
        //还原单个订单
        recallBoxItem(item) {
            var list = this.generateRecallData(item);
            this.showRecallDialog(list);
        },
        //批量还原订单
        recallBoxList() {
            if (this.multipleSelection.length == 0) {
                this.$message.error("请选择要还原的订单");
                return;
            }
            var list = [];
            this.multipleSelection.forEach((item) => {
                list.push(...this.generateRecallData(item));
            });
            this.showRecallDialog(list);
        },

        showRecallDialog(list) {
            this.recallDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.RecallBoxDialog.init(list);
            });
        },
        getDate(val) {
            return getDate(val);
        },
        getDate2(val) {
            return getDate2(val);
        },
        getTime(val) {
            return getTime(val);
        },
        getTime2(val) {
            return getTime2(val);
        },
        getMatou(val) {
            return getMatou(val);
        },
        //码放状态转成中文
        passFlag2String(status) {
            return passFlag2String(status);
        },
        //表格序号根据分页连续显示
        tableIndex(index) {
            return this.startRow + index;
        },
        //将数据中的字符串转为json
        getString2Json(data) {
            var json = eval("[" + data + "]")[0];
            return json;
        },
        //解决el-autocomplete 点击clearable后再输入无法显示建议列表的bug
        blurForBug() {
            document.activeElement.blur();
        },
        //设置单元格样式
        cellClass({ row, column, rowIndex, columnIndex }) {
            if (column.property) {
                //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
                if (
                    (column.property === "boxCusmov" && row.boxCusmov) ||
                    (column.property === "boxPassFlag" &&
                        row.boxPassFlag &&
                        row.boxPassFlag != "Y") ||
                    (column.property === "closeTime" &&
                        row.closeTime &&
                        this.judgeTimeclose(
                            row.closeTime,
                            this.$store.state.ship.portCloseTime,
                            row.departureTime,
                        )) ||
                    (column.property === "portCloseTime" &&
                        row.portCloseTime &&
                        this.judgeTimeclose(
                            row.portCloseTime,
                            this.$store.state.ship.portCloseTime,
                            row.departureTime,
                        )) ||
                    (column.property === "ctnApplyEndTime" &&
                        row.ctnApplyEndTime &&
                        this.judgeTimeclose(
                            row.ctnApplyEndTime,
                            this.$store.state.ship.ctnApplyEndTime,
                            row.departureTime,
                        )) ||
                    (column.property === "errorValue" && row.errorValue >= 5)
                ) {
                    return "pink_cell";
                }
            }
        },
        //判断时间是否小于设置的时间
        judgeTimeclose(time, markHour, isDepature) {
            const isDepatureValue = isDepature && isDepature !== "-";
            var date = new Date();
            var enddate = new Date(time);
            if (enddate > date) {
                return (enddate - date) / 1000 / 3600 < markHour;
            } else {
                return !isDepatureValue;
            }
        },
        selectable: function (row, index) {
            return row.sonCount;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.header-title{
    text-align:center;
    font-size: 32px;
    position:absolute;
    top: -10px;
    right:90px;
    color: #fff;
    font-weight: bold;
}
.tab{
    width:100px;
    height:42px;
}
#tabber{
    width:100px;
    height:42px;
    margin-left: -7px;
    margin-right: 20px;
}

/deep/input::-webkit-input-placeholder {
  color: #778899;
}

.search_input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background: #fff;
  align-items: baseline;
  height: 42px;

  // margin: auto;
  .el-input {
    width: 15rem;
  }

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}

.refresh_btn {
  height: 40px;
  margin: 0 1rem;
  cursor: pointer;
  // height: 4rem;
}

.p_green {
  color: var(--GREEN-1E9855);
}

.p_red {
  color: var(--RED-C11C20);
}

.el-select {
  width: 12rem;
}

p {
  text-align: center;
  margin: 0;
}

.txt_time {
}

.el-select .el-input {
  width: 130px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: none;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.small_title {
  line-height: 1.5;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  user-select: none;
}

.search_div_father {
  /deep/ .el-input__inner {
    border: none;
    width: auto;
  }
}

.box-card {
  top: 10px;
  margin-left: 20px;
  position: absolute;
  width: 240px;
}

.el-select .el-input {
  width: 13rem;
}

.query-input_center:before, .query-input_right:before {
  content: '';
  // position: absolute;
  height: 2rem;
  margin-top: 12px;
  border-right: 1px solid #ccc;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.data_div {
  min-height: 55vh;
  margin: 1rem;
  // margin-left: 6rem;
  // margin-right: 6rem;
  background: #fff;
  padding: 1rem 2rem;
  // border: 1px solid var(--RED-79031D);
  // border-radius: 4px;
}

.table_div {
  min-height: 40rem;
}

// .table_div.divline {

// }
.passIcon {
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
}

.failIcon {
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
}

.data_search_div {
  // margin: 0.5rem 0;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // background: #fff;
  .data_search_left_div {
    display: flex;
    flex-direction: row;
    align-items:center;
  }

  /deep/ .el-input-group {
    width: 25rem;
    // margin-left: 1rem;
  }

  /deep/ .el-input__inner {
    // border: 1px solid #DCDFE6;
    border: none;
  }

  /deep/ .el-input--suffix {
    padding: 0;
  }
}

/deep/ .el-table--border th.el-table__cell {
  color: #000 !important;
  text-align: center;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  font-weight: 700;
}

/deep/ .el-table .el-table__cell {
  padding: 2px 0;
  text-align: center;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #f1f3f6;
}

.el-tree {
  padding: 0 2rem 0 0;
}

.tree_btn_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex_div {
  display: flex;
  flex-direction: row;
}

.pagination_div {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table_control_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.add_remark_p {
  // cursor: pointer;
  color: rgb(170, 170, 170);
  user-select: none;
}

.line {
  width: 2px;
  height: 80%;
  background: var(--GRAY-dcdcdc);
}

.recall {
  cursor: pointer;
  color: rgb(0, 200, 13);
  user-select: none;
}

.top_div {
  background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.img_back {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.class_ship {
  width: 28px;
  height: 28px;
}

.addshow_class {
  font-size: 20px;
  float: left;
  // margin-left: 1rem;
}

.reduceshow_class {
  font-size: 30px;
  float: left;
  // margin-left: 1rem;
}

.together_num_class {
  font-size: 10px;
  float: right;
  // margin-right: 2rem;
  // margin-top: 1rem;
}
</style>
